.container,
.darkContainer {
    z-index: 3;
}
.today {
    color: currentColor;
}
.wrapperbottom > [class="DatePicker__calendarContainer -top"] {
    top: calc(100% + 20px);
}
[class="chakra-ui-dark"] .wrapperbottom > [class="DatePicker__calendarArrow"] {
    top: calc(100% + 10px) !important;
    border-width: 10px 10px 0px 10px;
    border-color: #0f1316 transparent transparent transparent;
}
[class="chakra-ui-light"] .wrapperbottom > [class="DatePicker__calendarArrow"] {
    top: calc(100% + 10px) !important;
    border-width: 10px 10px 0px 10px;
    border-color: rgb(255, 255, 255) transparent transparent transparent;
}
