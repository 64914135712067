.header_main {
    --header-margin-x: auto;
    left: 0;
    right: 0;
    top: 0;
    scroll-behavior: smooth;
    background-color: #f7f7f8;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 2rem;
    padding-right: 2rem;
    z-index: 1400;
    margin-left: var(--header-margin-x);
    margin-right: var(--header-margin-x);
    /* border-radius: 0.5rem; */
    transition-duration: 0.5s;
    transition-timing-function: ease;
    max-width: 1200px;
    width: calc(100% - 2 * var(--header-margin-x));
}
.sub {
    font-weight: 200;
    margin: 0rem 0.5rem 0;
    padding: 0.25rem 0.4rem;
    font-size: 0.7rem;
    font-family: monospace;
    border-radius: 5px;
    vertical-align: sub;
}
.sup {
    font-weight: 500;
    margin-right: 0.25rem;
    padding: 0.1rem 0.5rem;
    font-size: 0.7rem;
    border-radius: 5px;
}
.header_links_break {
    display: none;
}
.logo_container {
    transition: all 1.5s;
}

@media screen and (max-width: 1210px) {
    .header_main {
        --header-margin-x: 1rem;
    }
}
@media screen and (max-width: 850px) {
    .header_links_break {
        display: flex;
    }
    .header_links {
        display: none;
    }
}
@media screen and (max-width: 425px) {
    .drawer_links {
        flex-direction: column;
        align-items: center;
    }
    .drawer_links > a {
        margin: 0.75rem auto;
    }
}
