.cardCover {
    height: 140px;
    width: 100%;
    object-fit: cover;
}
.NotebookInfoCard {
    border-top-width: 1.5px;
    border-bottom-width: 1.5px;
    border-color: rgb(219, 222, 254);
}
@media screen and (max-width: 964px) {
    .notebooks_container {
        width: 80%;
    }
    .top_container {
        width: 82%;
    }
}
@media screen and (max-width: 940px) {
    .notebooks_container {
        width: 85%;
    }
}
@media screen and (max-width: 860px) {
    .notebooks_container {
        width: 90%;
    }
}
@media screen and (max-width: 800px) {
    .notebooks_container {
        width: 95%;
    }
}
@media screen and (max-width: 768px) {
    .notebooks_container {
        width: 100%;
    }
}
@media screen and (max-width: 760px) {
    .top_container {
        width: 79%;
    }
}
@media screen and (max-width: 670px) {
    .top_container {
        width: 76%;
    }
}
@media screen and (max-width: 610px) {
    .top_container {
        width: 73%;
    }
}
@media screen and (max-width: 560px) {
    .top_container {
        width: 70%;
    }
}
@media screen and (max-width: 520px) {
    .top_container {
        width: 66%;
    }
}
@media screen and (max-width: 480px) {
    .nb_misc_buttons {
        flex-direction: column;
    }
    .nb_misc_buttons > button {
        align-self: flex-end;
        margin-top: 0.25rem;
    }
    .top_container {
        width: 63%;
    }
}
@media screen and (max-width: 430px) {
    .top_container {
        width: 60%;
    }
    .notebooks_container {
        padding-left: 0;
        padding-right: 0;
    }
}
