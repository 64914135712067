.quote {
    text-shadow: 5px 3px 5px #494949, -5px 3px 5px #494949, 3px 5px 5px #494949,
        3px -5px 5px #494949;
}
@media screen and (max-width: 1000px) {
    .rightPanel {
        width: 75% !important;
    }
}
@media screen and (max-width: 900px) {
    .rightPanel {
        width: 70% !important;
    }
}
@media screen and (max-width: 805px) {
    .rightPanel {
        width: 65% !important;
    }
}
@media screen and (max-width: 780px) {
    .rightPanel {
        width: 60% !important;
    }
}
@media screen and (max-width: 730px) {
    .rightPanel {
        display: none;
        width: 55% !important;
    }
}
@media screen and (max-width: 710px) {
    .rightPanel {
        width: 45% !important;
    }
}
@media screen and (max-width: 630px) {
    .layout {
        flex-direction: column;
    }
    .rightPanel {
        height: 100%;
        width: 100% !important;
    }
}
