.overview_no_image > div {
    width: 100%;
}
.overview_coverPictureDarkHover {
    height: auto;
    width: auto;
    background: rgba(0, 0, 0, 0);
    opacity: 1;
    border-radius: 0%;
    grid-column: 1;
    grid-row: 1;
    padding: 0.5rem;
    z-index: 9;
    position: absolute;
    transition: 0.1s all;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}
.settings_thumbnailPictureDarkHover {
    height: 5rem;
    width: 5rem;
    background: rgba(0, 0, 0, 0);
    opacity: 0;
    border-radius: 100%;
    grid-column: 1;
    grid-row: 1;
    margin-left: 1rem;
    z-index: 9;
    position: absolute;
    transition: 0.1s all;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.settings_thumbnailPictureDarkHover:hover {
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
}
@media screen and (max-width: 701px) {
    .datePicker_container {
        flex-direction: column;
        align-items: center;
    }
    .ta_text {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }
}
