.message_recieved {
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: start;
    border-radius: 4px;
    border-width: 1px;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    background-color: var(--chakra-colors-RoboEpics-gold-200);
    color: black;
    align-self: flex-start;
}
/* .message_recieved::before,
.message_recieved::after {
    content: "";
    position: absolute;
    z-index: 2;
    bottom: -2px;
    right: -7px;
    height: 10px;
    border-left: 20px solid var(--chakra-colors-RoboEpics-gold-200);
    border-bottom-right-radius: 16px 14px;
    -webkit-transform: translate(0, -2px);
} */
.message_sent {
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: start;
    border-radius: 4px;
    border-width: 1px;
    width: 100%;
    font-size: 14px;
    background-color: var(--chakra-colors-RoboEpics-azure-200);
    color: black;
    align-self: flex-start;
}
.message_edit {
    position: absolute !important;
    left: -15px;
    bottom: 15px;
    opacity: 0;
}

.message_sent:hover .message_edit {
    opacity: 1;
    transition: all ease 0.4s;
}
