@media screen and (max-width: 768px) {
    .join_team_button {
        width: 35% !important;
    }
}
@media screen and (max-width: 568px) {
    .join_team_button {
        width: 40% !important;
    }
}
@media screen and (max-width: 508px) {
    .join_team_button {
        width: 45% !important;
    }
}
@media screen and (max-width: 470px) {
    .modal_content {
        width: 85% !important;
    }
}
@media screen and (max-width: 408px) {
    .join_team_button {
        width: 50% !important;
    }
}
@media screen and (max-width: 358px) {
    .join_team_button {
        width: 55% !important;
    }
}
@media screen and (max-width: 338px) {
    .join_team_button {
        width: 60% !important;
    }
}
