@media screen and (max-width: 768px) {
    .full_name_wrapper {
        flex-direction: column;
    }
    .email_wrapper {
        margin-top: 16px;
        direction: rtl;
    }
    .email_wrapper > input {
        direction: ltr;
    }
}
@media screen and (max-width: 900px) {
    .infos_container {
        flex-direction: column;
    }
}
@media screen and (max-width: 700px) {
    .contact_item_mail {
        flex: auto;
        max-width: 290px;
    }
}
@media screen and (max-width: 650px) {
    .full_name_wrapper {
        flex-direction: row;
    }
    .email_wrapper {
        margin-top: 0;
        direction: ltr;
    }
    .email_wrapper > input {
        direction: ltr;
    }
}
@media screen and (max-width: 425px) {
    .full_name_wrapper {
        flex-direction: column;
    }
    .email_wrapper {
        margin-top: 16px;
        direction: rtl;
    }
    .email_wrapper > input {
        direction: ltr;
    }
}
