.send_btn {
    place-self: flex-end;
}
@media screen and (max-width: 1300px) {
    .result_form_wrapper {
        width: 45%;
    }
}
@media screen and (max-width: 1000px) {
    .result_form_wrapper {
        width: 50%;
    }
}
@media screen and (max-width: 900px) {
    .result_form_wrapper {
        width: 55%;
    }
}
@media screen and (max-width: 800px) {
    .result_form_wrapper {
        width: 60%;
    }
}
@media screen and (max-width: 700px) {
    .result_form_wrapper {
        width: 65%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}
@media screen and (max-width: 600px) {
    .result_form_wrapper {
        width: 75%;
    }
}
@media screen and (max-width: 550px) {
    .result_form_wrapper {
        width: 80%;
    }
}
@media screen and (max-width: 500px) {
    .result_form_wrapper {
        width: 85%;
        padding-left: 0;
        padding-right: 0;
    }
}
@media screen and (max-width: 450px) {
    .result_form_wrapper {
        width: 90%;
    }
}
@media screen and (max-width: 400px) {
    .result_form_wrapper {
        width: 95%;
    }
}
@media screen and (max-width: 380px) {
    .result_form_wrapper {
        width: 100%;
    }
}
