@media screen and (max-width: 1380px) {
    .card {
        width: 70% !important;
    }
}
@media screen and (max-width: 1215px) {
    .card {
        width: 75% !important;
    }
}
@media screen and (max-width: 1155px) {
    .card {
        width: 80% !important;
    }
}
@media screen and (max-width: 1105px) {
    .card {
        width: 85% !important;
    }
}
@media screen and (max-width: 1055px) {
    .card {
        width: 90% !important;
    }
}
@media screen and (max-width: 1015px) {
    .card {
        width: 95% !important;
    }
}
@media screen and (max-width: 1000px) {
    .card {
        width: 80% !important;
    }
}
@media screen and (max-width: 965px) {
    .card {
        width: 85% !important;
    }
}
@media screen and (max-width: 925px) {
    .card {
        width: 90% !important;
    }
}
@media screen and (max-width: 900px) {
    .card {
        width: 95% !important;
    }
}
@media screen and (max-width: 835px) {
    .card {
        width: 100% !important;
    }
}
@media screen and (max-width: 750px) {
    .remember_me {
        flex-direction: column;
    }
    .remember_me > label,
    .remember_me > a {
        margin: 0.25rem 0 0.5rem 0;
    }
}
@media screen and (max-width: 750px) {
    .card {
        width: 65% !important;
    }
}
@media screen and (max-width: 630px) {
    .remember_me {
        flex-direction: row;
    }
    .remember_me > label,
    .remember_me > a {
        margin: initial;
    }
    .card {
        width: 80% !important;
    }
}
@media screen and (max-width: 570px) {
    .remember_me {
        flex-direction: column;
    }
    .remember_me > label,
    .remember_me > a {
        margin: 0.25rem 0 0.5rem 0;
    }
    .card {
        width: 100% !important;
    }
}

@media screen and (max-width: 460px) {
    .login_form_wrapper {
        padding-left: unset;
        padding-right: unset;
    }
    .remember_me {
        flex-direction: row;
    }
    .remember_me > label,
    .remember_me > a {
        margin: initial;
    }
}
@media screen and (max-width: 400px) {
    .remember_me {
        flex-direction: column;
    }
    .remember_me > label,
    .remember_me > a {
        margin: 0.25rem 0 0.5rem 0;
    }
}
