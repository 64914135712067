.socialWrapper > span svg {
    transition: 0.3s;
}
.socialWrapper > span[data-logo="twitter"] svg:hover {
    color: #08a0e9;
    fill: #08a0e9;
}
.socialWrapper > span[data-logo="linkedin"] svg:hover {
    color: #0077b5;
    fill: #0077b5;
}
.socialWrapper > span[data-logo="instagram"] svg:hover {
    color: #dd2a7b;
    fill: #dd2a7b;
}
.socialWrapper[data-color="dark"] > span[data-logo="github"] svg:hover {
    color: white;
    fill: white;
}
.socialWrapper[data-color="light"] > span[data-logo="github"] svg:hover {
    color: black;
    fill: black;
}
/* .socialWrapper[data-color="dark"] > span svg:hover {
    color: white;
    fill: white;
}
.socialWrapper[data-color="light"] > span svg:hover {
    color: black;
    fill: black;
} */
.metaWrapper > div:first-child {
    margin-right: 0;
}
.metaWrapper > div {
    margin-right: 36px;
}
@media screen and (max-width: 768px) {
    .info_container {
        flex-direction: column;
        max-width: 540px !important;
    }
    .profile_picture {
        margin: 0 auto;
    }
    .user_social_wrapper {
        justify-content: space-between;
    }
    .misc_buttons {
        justify-content: center;
    }
}
@media screen and (max-width: 575px) {
    .info_container {
        max-width: 360px !important;
    }
    .metaWrapper {
        justify-content: space-evenly;
        margin: 0.5rem auto;
    }
}
@media screen and (max-width: 425px) {
    .info_container {
        max-width: 300px !important;
        padding-left: 0;
        padding-right: 0;
    }
    .title_wrapper {
        justify-content: center;
    }
    .title_wrapper:first-child {
        margin-right: 5rem;
    }
    .title_wrapper > button {
        margin-right: 0.75rem;
    }
    .misc_buttons {
        justify-content: flex-end;
        margin-right: 2rem;
    }
    .user_social_wrapper {
        margin: 0.25rem auto;
        flex-direction: column;
    }
    .socialWrapper {
        margin: 0.75rem auto;
    }
    .socialWrapper > span {
        margin: auto 0.5rem;
    }
    .description {
        text-align: center;
        width: 80%;
        margin: 0.5rem auto 1rem;
    }
}
