.fields {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.fields > div:first-child {
    margin-left: 2rem;
}
@media screen and (max-width: 950px) {
    .other_info_wrapper {
        padding-right: 0;
        margin-top: 1.75rem;
    }
    .social_info_wrapper {
        margin-top: 1rem;
        padding-right: 0;
    }
}
@media screen and (max-width: 768px) {
    .fields {
        flex-direction: column !important;
    }
    .fields > div:first-child {
        margin-left: 0;
        margin-bottom: 1rem;
    }
}
