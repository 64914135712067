.root {
    direction: ltr;
    height: 100%;
    margin-top: 36px;
}
.header{
    margin-bottom: 16px;
}
.header *{
    font-family: 'Roboto';
}
.header h1{
    font-size: 72px;
    margin-bottom: 0;
}
.header h2{
    font-size: 26px;
    margin-top: -20px;
}
.window *{
    font-family: "Courier New", "Proxima Nova", "Helvetica Neue", helvetica, arial, sans-serif !important;
    font-size: 16px;
    line-height: 1.5rem;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.window {
    font-weight: 300;
    font-size: 16px;
    width: 80%;
    margin: 0 auto 2rem;
    box-shadow: 0 0.25rem 0.5rem #12181e;
    border-radius: 0 0 0.1rem 0.1rem;
}

.bar {
    background: #191919;
    height: 36px;
    border-radius: 0.5rem 0.5rem 0 0;
}

.btn {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    display: block;
    background: #f6b73e;
    position: relative;
    margin-left: 38px;
    top: 12px;
}

.btn::before,
.btn::after {
    width: 12px;
    height: 12px;
    content: " ";
    border-radius: 100%;
    display: block;
    position: absolute;
}
.btn::before {
    background: #f55551;
    margin-left: -20px;
}
.btn::after {
    background: #32c146;
    margin-left: 20px;
}

.body {
    height: 24rem;
    max-height: 50vh;
    background: #232323;
    padding: 18px;
}
/* pre {
    margin: 0;
} */
.pulse {
    animation: pulse 1s ease-in-out infinite;
}

@keyframes pulse {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.command {
    color: #32c146;
}

.comment {
    opacity: 0.5;
}
.prompt{}