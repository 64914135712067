.container,
.container_inner {
    max-width: 1350px;
    justify-content: center;
}
@media screen and (max-width: 900px) {
    .container_inner {
        flex-direction: column;
    }
}
