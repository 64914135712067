.path_form_helper {
    visibility: hidden;
    display: none;
}
.menu_item_li {
    height: 36px;
}
.problem_link {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.menu_item_timer {
    position: relative;
    margin-right: auto;
    margin-inline-start: auto !important;
}
.menu_item_link_text {
}
.menu_item_link:hover,
.menu_item_link a:hover {
    text-decoration: none !important;
}
.sidebar_wrapper {
    width: 210px;
    transition: width 0.5s ease-out;
}

@media screen and (max-width: 1110px) {
    /* .sidebar_wrapper {
        width: 64px !important;
    } */
    /* .menu_item_link_text,
    .menu_item_branch_label {
        height: 1px;
        width: 1px;
        overflow: hidden;
        white-space: nowrap;
    }
    .menu_item_li {
        width: auto;
    }
    .menu_item_link {
        text-align: center;
    }
    .menu_item_link svg {
        margin: 0;
    } */
}

.branch_section_expanded {
    height: 600px;
}

.branch_section {
    height: 0;
}

.heightAnimation {
    animation: heightChange 1s 1;
}

@keyframes heightChange {
    0% {
        height: 0;
    }
    100% {
        height: 100%;
    }
}
@media screen and (max-width: 661px) {
    .datePicker_container {
        flex-direction: column;
        align-items: center;
    }
    .ta_text {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

@media screen and (max-width: 600px) {
    .new_problem_modal_content {
        width: 75%;
    }
}
@media screen and (max-width: 550px) {
    .path_form_helper {
        visibility: visible;
        display: flex;
    }
}
