.competition_card_wrapper {
    transition: box-shadow 0.25s;
}

.competition_card_wrapper:hover,
.competition_card_wrapper:focus,
.competition_card_wrapper:active {
    -webkit-box-shadow: 0px 10px 13px -7px #4e4e4e, 0px 9px 16px 13px rgba(120, 120, 120, 0);
    box-shadow: 0px 10px 13px -7px #4e4e4e, 0px 9px 16px 13px rgba(120, 120, 120, 0);
}
@media screen and (max-width: 768px) {
    .competition_card_wrapper {
        width: 90%;
    }
}
@media screen and (max-width: 520px) {
    .main_status_container {
        flex-direction: column;
    }
    .main_title_container {
        width: 100%;
    }
}
