.list_icon {
    width: 24px !important;
    height: 24px !important;
}
@media screen and (max-width: 1024px) {
    .container {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .text {
        text-align: justify;
    }
}
