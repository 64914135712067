.re-markdown-root h1,
.re-markdown-root h2,
.re-markdown-root h3,
.re-markdown-root h4,
.re-markdown-root h5,
.re-markdown-root h6 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: 600;
    line-height: 1.25;
}
.re-markdown-root > h1,
.re-markdown-root > h2 {
    margin-top: 8px;
}
.re-markdown-root h1,
.re-markdown-root h2 {
    padding-bottom: 0.3em;
    border-bottom: 1px solid var(--chakra-colors-RoboEpics-black-800);
}
.re-markdown-light h1,
.re-markdown-light h2 {
    padding-bottom: 0.3em;
    border-bottom: 1px solid var(--chakra-colors-RoboEpics-black-400);
}
.re-markdown-root h1 {
    font-size: 2em;
}
.re-markdown-root h2 {
    font-size: 1.5em;
}
.re-markdown-root h3 {
    font-size: 1.25em;
}
.re-markdown-root h4 {
    font-size: 1em;
}
.re-markdown-root h5 {
    font-size: 0.875em;
}
.re-markdown-root h6 {
    font-size: 0.85em;
}
.re-markdown-root details,
.re-markdown-root dl,
.re-markdown-root ol,
.re-markdown-root p,
.re-markdown-root pre,
.re-markdown-root table,
.re-markdown-root ul {
    margin-top: 0;
    margin-bottom: 16px;
}
.re-markdown-root ol {
    list-style: decimal;
    margin-right: 2rem;
}
.re-markdown-root ul {
    list-style: disc;
    margin-right: 2rem;
}
.re-markdown-root td,
.re-markdown-root th {
    padding: 8px;
    border-width: 2px;
    font-size: 14px;
}
.re-markdown-root td {
    border-collapse: collapse;
}
.re-markdown-root img {
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px;
    margin-bottom: 16px;
}
.re-markdown-root table {
    margin-left: auto;
    margin-right: auto;
}
.re-markdown-light.re-markdown-root blockquote {
    background: #e2e2e2;
    border-left: 10px solid #888;
    margin: 1.5em 10px;
    padding: 1em 10px 0.1em 10px;
    quotes: "\201C""\201D""\2018""\2019";
}
.re-markdown-root blockquote {
    background: #4b4b4b;
    border-left: 10px solid #888;
    margin: 1.5em 10px;
    padding: 1em 10px 0.1em 10px;
    quotes: "\201C""\201D""\2018""\2019";
}
.re-markdown-root p code {
    background: #4b4b4b;
    border-radius: 4px;
    padding: 2px 3px;
}
.re-markdown-light.re-markdown-root p code {
    background: #e2e2e2;
    border-radius: 4px;
    padding: 2px 3px;
}
.re-markdown-light.re-markdown-root > pre {
    width: 100%;
    background: #ebebeb;
    border-radius: 4px;
    padding: 0.5em 1em;
}
.re-markdown-root > pre {
    width: 100%;
    background: #000;
    border-radius: 4px;
    padding: 0.5em 1em;
}

.re-markdown-root.re-markdown-light a,
.re-markdown-root.re-markdown-dark a {
    transition: all 300ms ease;
    text-decoration: underline;
}

.re-markdown-root.re-markdown-light a:hover {
    color: var(--chakra-colors-blue-500);
}
.re-markdown-root.re-markdown-dark a:hover {
    color: var(--chakra-colors-RoboEpics-azure-500);
}
