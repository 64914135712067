.footer_container_light {
    --tw-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.1),
        0 -1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1.5rem;
    padding-bottom: 2.5rem;
}
.footer_container_dark {
    --tw-shadow: 0 -1px 3px 0 rgba(102, 102, 102, 0.1),
        0 -1px 2px 0 rgba(94, 94, 94, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1.5rem;
    padding-bottom: 2.5rem;
}
.footer_row {
    display: flex;
    flex-direction: row;
}
.first_row {
    max-width: 750px;
    margin-bottom: 1.75rem;
}
.second_row {
    margin-bottom: 2.5rem;
    max-width: 740px;
}
.second_row > :nth-child(1) {
    margin-left: 2.5rem;
}
.second_row > :nth-child(2) {
    max-width: 348px;
}
.footer_text_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 352px;
}
.footer_logo {
    width: 188px;
    height: 190px;
    padding: 0 0.8rem;
    margin-left: 1.5rem;
}
.footer_powered_by {
    max-width: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}
.arvan_cloud {
    max-width: 160px;
    max-height: 25px;
    object-fit: fill;
}
.footer_rights {
    max-width: 510px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 1rem;
}
.footer_socials {
    max-width: 630px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
}
.fs_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 105px;
}
.fs_icon,
.fs_icon_dark {
    margin-right: 4px;
}
.footer_socials > a {
    margin: auto;
}
.footer_links_container {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: space-between;
    align-items: center;
}
@media screen and (max-width: 768px) {
    .footer_container_light,
    .footer_container_dark {
        padding: 2rem;
    }
    .footer_row {
        width: 75%;
    }
    .first_row {
        flex-direction: column-reverse;
    }
    .second_row {
        flex-direction: column;
    }
    .footer_text_container {
        max-width: max-content;
    }
    .second_row > :nth-child(1) {
        margin-left: 0rem;
    }
    .second_row > :nth-child(2) {
        max-width: max-content;
    }
}
@media screen and (max-width: 710px) {
    .footer_socials {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-row-gap: 1rem;
        grid-column-gap: 1rem;
    }
}
@media screen and (max-width: 600px) {
    .footer_links_container {
        flex-direction: column;
        margin-bottom: 3rem;
    }
    .footer_logo {
        width: 250px;
        height: auto;
    }
}

@media screen and (max-width: 500px) {
    .footer_socials {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-row-gap: 1.5rem;
    }
}
