.landing_container {
    display: flex;
    position: relative;
    flex-direction: column;
    max-width: 1120px;
    width: 100%;
    margin-bottom: 135px;
    align-items: center;
    overflow-x: hidden;
    overflow-y: clip;

    padding-top: 100px;
    /* margin-top: calc(100vh - 6rem); */
}
.landing_container > div {
    z-index: 5;
    overflow-x: hidden;
}
.no_image > div {
    width: 100%;
}
.blob {
    position: absolute;
    width: 500px;
    height: 500px;
    opacity: 0.5;
    animation: morph 8s ease-in-out infinite;
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    transition: all 1s ease-in-out;
    z-index: 4 !important;
}
.blob_one {
    left: -250px;
    top: 2447px;
    background: linear-gradient(180deg, #ff5f6d 0%, #ffc371 100%);
}
.blob_two {
    left: 1255px;
    top: 3477px;
    background: linear-gradient(180deg, rgba(194, 229, 156, 0.3) 0%, rgba(100, 179, 244, 0.3) 100%);
}
.blob_three {
    top: 3800px;
    left: -155px;
    background: linear-gradient(180deg, rgba(164, 142, 211, 0.3) 0%, rgba(196, 224, 229, 0.3) 100%);
}
@keyframes morph {
    0% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }

    50% {
        border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
    }

    100% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }
}

.hero_banner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 862px;
    max-height: 653px;
    width: 100%;
}
.slider_container,
.slider_container > div {
    max-height: 653px;
}
.hero_text_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    align-self: center;
    margin-right: 30px;
}
.trusted_us {
    display: flex;
    flex-direction: column;
    width: 60%;
    align-items: center;
    margin-top: 8.5rem;
}
.trusted_logos {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.statistics {
    display: flex;
    flex-direction: row;
    width: 80%;
    align-items: center;
    justify-content: space-between;
    margin-top: 8.5rem;
}
.partner_logo {
    width: 100px;
    height: 50px;
}
.tools_container {
    display: flex;
    flex-direction: row;
    margin-top: 16rem;
    justify-content: space-between;
    max-width: 928px;
    max-height: 571px;
}
.tools_text_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    align-self: center;
    margin-left: 112px;
}
.ways_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 16rem;
    max-width: 837px;
}
.ways_grid {
    grid-row-gap: var(--chakra-space-16);
    grid-column-gap: var(--chakra-space-32);
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
.ways_way_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.gathering_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 1135px;
    margin-top: 16rem;
    align-items: flex-start;
    max-height: 663px;
}
.gathering_text_container {
    display: flex;
    flex-direction: column;
    margin-right: 145px;
}
.gathering_image {
    width: 448px;
}
.gathering_grid_item {
    display: inline-flex;
    align-items: flex-start;
}
.other_tools_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 16rem;
    max-width: 836px;
}
.other_tools_tool_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-start;
}
.other_tools_tool_text_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: right;
    max-width: 314px;
}
.other_tools_tool_icon,
.ways_way_icon {
    margin-left: 1rem;
    flex-shrink: 0;
}
.contests_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 16rem;
    margin-bottom: 16rem;
    max-width: 900px;
}
.contests {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
.card {
    width: 400px;
    height: 424px;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: var(--chakra-colors-RoboEpics-black-100);
}
.dark_card {
    width: 400px;
    height: 424px;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 4px rgba(136, 136, 136, 0.25);
    background-color: var(--chakra-colors-RoboEpics-dark-800);
    margin-left: 2.5rem;
}
.contest_image {
    width: 100%;
    height: 200px;
    border-radius: 8px 8px 0 0;
}
.contest_data {
    padding-top: 0.5rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    max-height: 224px;
    height: 100%;
}
.cd_title_container {
    width: 300px;
}
.cd_footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.cd_footer_right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 190px;
}
.xero_banner {
    background-color: #14181d;
    max-width: 1120px;
    width: 100%;
    font-family: JetBrainsMono;
    direction: ltr;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid 4px;
    padding: 0.25rem 0.5rem 2rem;
    margin-bottom: 135px;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    border-radius: 3px;
}
.hax0r {
    align-self: flex-start;
    margin-bottom: 0;
    color: #03b300;
    font-size: 16px;
}
.XeroCTF {
    font-size: 100px;
    font-weight: 700;
    -webkit-text-stroke: 2px white;
    margin-bottom: 0;
    color: transparent;
    text-shadow: 0 0 13px #fff, 0 0 42px #03b300, 0 0 82px #03b300, 0 0 92px #03b300, 0 0 102px #03b300, 0 0 151px #03b300;
    animation: pulsate 1s infinite alternate;
}
.pizza {
    color: white;
    font-size: 20px;
}

@keyframes pulsate {
    100% {
        text-shadow: 0 0 12px #fff, 0 0 40px #03b300, 0 0 80px #03b300, 0 0 90px #03b300, 0 0 100px #03b300, 0 0 150px #03b300;
    }
}

@media screen and (max-width: 1200px) {
    .gathering_text_container {
        margin-right: 100px;
    }
    .gathering_image {
        width: 400px;
    }
}

@media screen and (max-width: 1100px) {
    .xero_banner {
        width: 90%;
    }
    .gathering_container {
        padding-left: 2rem;
        padding-right: 2rem;
        max-height: fit-content;
    }
    .gathering_image {
        width: 360px;
    }
}

@media screen and (max-width: 1000px) {
    .gathering_image {
        width: 310px;
    }
}

@media screen and (max-width: 960px) {
    .tools_text_container {
        margin-left: 50px;
    }
    .ways_container {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
    .ways_grid {
        grid-column-gap: var(--chakra-space-24);
    }
    .gathering_text_container {
        margin-right: 70px;
    }
    .gathering_container {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@media screen and (max-width: 880px) {
    .tools_text_container {
        margin-left: 16px;
    }
    .other_tools_container {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .contests > :nth-child(4),
    .contests > :nth-child(3) {
        display: none;
    }
    .statistics {
        flex-wrap: wrap;
    }
    .statistics > div {
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .statistics > div > :first-child {
        line-height: 30px;
        margin-bottom: 0.25rem;
        border-bottom-width: 4px;
        border-bottom-style: solid;
        border-radius: 0.25rem;
    }
}

/* @media screen and (max-width: 850px) {
    .landing_container {
        margin-top: calc(100%);
    }
} */

@media screen and (max-width: 830px) {
    .xero_banner {
        width: 100%;
    }
    .landing_container {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .hero_banner {
        flex-direction: column;
        height: 100%;
        max-height: max-content;
        /* margin-top: 10%; */
    }
    .hero_text_container {
        margin-right: 0;
        margin-top: 28px;
        align-items: flex-start;
        max-width: 384px;
    }
    .trusted_us {
        align-items: flex-start;
        width: 80%;
        margin-top: 6.25rem;
    }
    .partner_logo {
        width: 80px;
    }
    .tools_container {
        flex-direction: column-reverse;
        max-height: max-content;
        height: 100%;
        margin-top: 7.75rem;
    }
    .tools_text_container {
        margin-left: 0;
        margin-top: 40px;
    }
    .ways_container {
        padding-right: 0rem;
        padding-left: 0rem;
        margin-top: 10rem;
    }
    .ways_grid {
        grid-column-gap: var(--chakra-space-16);
    }
    .gathering_container {
        flex-direction: column;
        height: 100%;
        max-height: max-content;
        padding-left: 0;
        padding-right: 0;
        align-items: center;
        margin-top: 23rem;
    }
    .gathering_text_container {
        max-width: 425px;
        margin-right: auto;
        margin-top: 4.5rem;
    }
    .gathering_image {
        max-width: 500px;
        width: 100%;
    }
    .other_tools_container {
        margin-top: 21rem;
    }
    .contests {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

@media screen and (max-width: 768px) {
    .XeroCTF {
        font-size: 80px;
    }
    .pizza {
        font-size: 16px;
    }
    .hax0r {
        font-size: 13px;
    }
    .blob {
        display: none;
    }
    .other_tools_container {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

/* @media screen and (max-width: 730px) {
    .hero_banner {
        margin-top: 30%;
    }
} */

@media screen and (max-width: 660px) {
    .other_tools_container {
        padding-left: 0;
        padding-right: 0;
    }
    /* .hero_banner {
        margin-top: 50%;
    } */
}

/* @media screen and (max-width: 580px) {
    .hero_banner {
        margin-top: 75%;
    }
} */

@media screen and (max-width: 550px) {
    .trusted_us {
        width: 100%;
    }
    .partner_logo {
        width: 70px;
    }
}

@media screen and (max-width: 500px) {
    .XeroCTF {
        font-size: 60px;
    }
    .pizza {
        font-size: 14px;
    }
    .hax0r {
        font-size: 11px;
    }
    .ways_grid {
        grid-column-gap: var(--chakra-space-12);
    }
    .card,
    .dark_card {
        height: 400px;
        width: 300px;
    }
    .cd_title_container {
        width: 250px;
    }
    .contest_image {
        height: 150px;
    }
    .contest_data {
        max-height: 250px;
    }
    .cd_footer {
        flex-direction: column;
        align-items: flex-start;
    }
    .cd_footer_right {
        max-width: 100%;
        width: 100%;
        justify-content: flex-start;
    }
    .cd_footer_left {
        place-self: flex-end;
        margin-top: 1rem;
    }
    /* .hero_banner {
        margin-top: 100%;
    } */
}

/* @media screen and (max-width: 450px) {
    .hero_banner {
        margin-top: 120%;
    }
} */

@media screen and (max-width: 425px) {
    .ways_grid {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-row-gap: var(--chakra-space-10);
    }
    .ways_way_container {
        flex-direction: column;
    }
    .ways_way_icon {
        margin-bottom: 0.75rem;
    }
    /* .hero_banner {
        margin-top: 140%;
    } */
}

@media screen and (max-width: 400px) {
    .partner_logo {
        width: 50px;
    }
    /* .hero_banner {
        margin-top: 100vh;
    } */
}

/* @media screen and (max-width: 340px) {
    .hero_banner {
        margin-top: calc(100vh + 30%);
    }
} */
