@media screen and (max-width: 500px) {
    .modal_content {
        width: 80% !important;
    }
}
@media screen and (max-width: 400px) {
    .modal_content {
        width: 75% !important;
    }
}
