.chosen_dataset_card_light {
    border-color: #0077b6;
}
.chosen_dataset_card_dark {
    border-color: #fff;
}
.loaded_more {
}
.not_loaded_more {
    backdrop-filter: blur(2px);
}

@media screen and (max-width: 360px) {
    .container {
        margin: auto;
    }
}
