.eval_file_name {
    max-width: 300px;
}
@media screen and (max-width: 1410px) {
    .eval_nofile_container {
        flex-direction: column;
    }
    .eval_nofile_container > :first-child {
        align-self: flex-start;
        margin-bottom: 0.5em;
    }
    .eval_nofile_container > :nth-child(2) {
        align-self: flex-end;
    }
}
@media screen and (max-width: 1300px) {
    .gimu_rules_keys_container {
        flex-direction: column;
    }
    .gimu_rules_keys_label {
        margin-bottom: 0.5rem !important;
        align-self: flex-start;
    }
    .gimu_rules_stack {
        padding-left: 0.5rem !important;
    }
}
@media screen and (max-width: 1240px) {
    .gimu_rules_stack {
        padding-left: 0 !important;
    }
    .gimu_role_player_type {
        width: 75%;
    }
    .gimu_rules_methods_container {
        flex-direction: column;
    }
    .gimu_rules_methods_label {
        margin-bottom: 0.5rem !important;
        align-self: flex-start;
    }
}
@media screen and (max-width: 900px) {
    .basic_numberinput_container {
        flex-direction: column;
    }
    .basic_numberinput_container {
        margin-bottom: 1rem !important;
    }
    .basic_numberinput_label {
        margin-bottom: 0.5rem !important;
        align-self: flex-start;
    }
    .gimu_role_player_type {
        width: 85%;
    }
    .gimu_rules_collapse,
    .gimu_role_resource_stack {
        margin-right: 4rem !important;
    }
    .gimu_role_resource_stack {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .gimu_rules_keys_wrapper {
        flex-direction: column;
    }
    .gimu_rules_keys_wrapper > * {
        margin: 0.25rem 0rem !important;
    }
}
@media screen and (max-width: 850px) {
    .gimu_rules_collapse,
    .gimu_role_resource_stack {
        margin-right: 2rem !important;
    }
}
@media screen and (max-width: 810px) {
    .gimu_rules_collapse,
    .gimu_role_resource_stack {
        margin-right: 0rem !important;
    }
}
@media screen and (max-width: 780px) {
    .gimu_role_player_type {
        width: 95%;
    }
    .gimu_main_container {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
@media screen and (max-width: 768px) {
    .basic_checkbox {
        padding: 0.5rem 0rem;
    }
}
@media screen and (max-width: 690px) {
    .eval_file_name {
        max-width: 250px;
    }
}
@media screen and (max-width: 670px) {
    .basic_form_container,
    .eval_form_container {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
@media screen and (max-width: 650px) {
    .eval_actions_button_container {
        flex-direction: column-reverse;
        justify-content: space-between;
    }
    .eval_actions_button_container > :first-child {
        margin-left: 0 !important;
    }
    .eval_actions_button_container > * {
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
        width: 100%;
        align-self: center;
    }
}
@media screen and (max-width: 635px) {
    .basic_button_container {
        justify-content: space-between;
    }
}
@media screen and (max-width: 630px) {
    .eval_file_name {
        max-width: 200px;
    }
}
@media screen and (max-width: 580px) {
    .eval_file_name {
        max-width: 175px;
    }
    .gimu_role_player_type {
        flex-direction: column;
        width: 100%;
    }
    .gimu_role_player_type_label {
        margin-bottom: 0.5rem !important;
        align-self: flex-start;
    }
    .gimu_rules_methods_wrapper {
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
        grid-column-gap: var(--chakra-space-16) !important;
    }
}
@media screen and (max-width: 550px) {
    .eval_gitlab_buttons_container,
    .gimu_numberinput_container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .gitlab_btn {
        align-self: center;
        margin-bottom: 0.5em;
    }
    .eval_file_status_container {
        flex-direction: column;
    }
    .eval_file_status_container > :first-child {
        margin-bottom: 0.5rem;
    }
    .eval_file_name {
        max-width: 100%;
    }
    .eval_progressbar {
        width: 75% !important;
    }
}
@media screen and (max-width: 480px) {
    .eval_nofile_container > button {
        align-items: center;
        margin: 0.5rem 0;
    }
    .gimu_actor_header,
    .gimu_director_header,
    .gimu_rules_header {
        flex-direction: column;
    }
    .gimu_actor_header > button,
    .gimu_director_header > button,
    .gimu_rules_header > button {
        margin: 0.5rem auto;
    }
    .gimu_rules_methods_wrapper {
        grid-column-gap: var(--chakra-space-4) !important;
    }
    .gimu_role_container,
    .gimu_rule_container {
        flex-direction: column-reverse;
    }
    .gimu_cntrlr_btn_container {
        align-self: flex-end;
    }
}
@media screen and (max-width: 425px) {
    .basic_form_container,
    .eval_form_container {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .gimu_rules_methods_wrapper {
        grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    }
}
