@media screen and (max-width: 1380px) {
    .card {
        width: 70% !important;
    }
}
@media screen and (max-width: 1215px) {
    .card {
        width: 75% !important;
    }
}
@media screen and (max-width: 1155px) {
    .card {
        width: 80% !important;
    }
}
@media screen and (max-width: 1105px) {
    .card {
        width: 85% !important;
    }
}
@media screen and (max-width: 1055px) {
    .card {
        width: 90% !important;
    }
}
@media screen and (max-width: 1015px) {
    .card {
        width: 95% !important;
    }
}
@media screen and (max-width: 1000px) {
    .card {
        width: 80% !important;
    }
}
@media screen and (max-width: 965px) {
    .card {
        width: 85% !important;
    }
}
@media screen and (max-width: 925px) {
    .card {
        width: 90% !important;
    }
}
@media screen and (max-width: 900px) {
    .card {
        width: 95% !important;
    }
}
@media screen and (max-width: 835px) {
    .card {
        width: 100% !important;
    }
}
@media screen and (max-width: 730px) {
    .card {
        width: 65% !important;
    }
}
@media screen and (max-width: 630px) {
    .card {
        width: 75% !important;
    }
}

@media screen and (max-width: 530px) {
    .card {
        width: 100% !important;
    }
}
@media screen and (max-width: 460px) {
    .signup_form_wrapper {
        padding-left: unset;
        padding-right: unset;
    }
}

@media screen and (max-width: 375px) {
    .card {
        width: 100% !important;
    }
}
