.competition_card_wrapper {
    transition: background-color 0.1s;
    /* transition: box-shadow 0.25s; */
}
[class="chakra-ui-dark"] .competition_card_wrapper {
    --tw-shadow: 0 1px 3px 0 rgb(185 185 185 / 10%),
        0 1px 2px 0 rgb(255 255 255 / 6%);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.competition_card_wrapper:hover,
.competition_card_wrapper:focus,
.competition_card_wrapper:active {
    /* -webkit-box-shadow: 0px 10px 13px -7px #000000,
        0px 9px 16px 13px rgba(120, 120, 120, 0);
    box-shadow: 0px 10px 13px -7px #000000,
        0px 9px 16px 13px rgba(120, 120, 120, 0); */
}
@media screen and (max-width: 768px) {
    .competition_card_wrapper {
        width: 90%;
    }
}
@media screen and (max-width: 520px) {
    .main_status_container {
        flex-direction: column;
    }
    .main_title_container {
        width: 100%;
    }
}
