.main_data_avatar {
    place-self: flex-start;
    z-index: 5;
}
.main_status_wrapper {
    flex-basis: 72%;
}
.google_colab_btn > :first-child,
.gitlab_btn > :first-child {
    margin-left: 0;
}
/* .main_wrapper {
    flex-basis: 72%;
} */
.no_image > div {
    width: 100%;
}
.thumbnailPictureDarkHover {
    height: 4rem;
    width: 4rem;
    background: rgba(0, 0, 0, 0);
    opacity: 0;
    border-radius: 100%;
    grid-column: 1;
    grid-row: 1;
    margin-left: 1rem;
    z-index: 9;
    position: absolute;
    transition: 0.1s all;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.coverPictureDarkHover {
    height: auto;
    width: auto;
    background: rgba(0, 0, 0, 0);
    opacity: 1;
    border-radius: 0%;
    grid-column: 1;
    grid-row: 1;
    padding: 0.5rem;
    z-index: 9;
    position: absolute;
    transition: 0.1s all;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}

.thumbnailPictureDarkHover:hover {
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
}
@media screen and (max-width: 920px) {
    .main_container {
        flex-direction: column;
    }
    .button_container {
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 1rem;
    }
    .google_colab_btn {
        margin-left: 0.5rem;
    }
    .gitlab_btn {
        margin-right: 0.5rem;
        margin-top: 0;
    }
    .main_status_wrapper {
        width: 100%;
        flex-basis: 100%;
    }
}
@media screen and (max-width: 600px) {
    .status_wrapper {
        margin-right: 0;
    }
}
@media screen and (max-width: 530px) {
    .status_wrapper {
        flex-direction: column;
    }
    .colab_status {
        margin-right: 0;
        margin-top: 0.5rem;
    }
    .gitlab_status {
        margin-bottom: 0.5rem;
    }
    .button_container {
        flex-direction: column-reverse;
        align-items: center;
    }
    .gitlab_btn,
    .google_colab_btn {
        margin: 0.5rem 0;
    }
}
