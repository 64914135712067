.sidebar_wrapper {
    flex: 0 0 270px;
    transition: transform 0.5s cubic-bezier(0.18, 0.01, 0.25, 1);
    -webkit-transition: transform 0.5s cubic-bezier(0.18, 0.01, 0.25, 1);
}
.content_wrapper {
    max-width: 1240px;
}

.sidebar_toggle_in_content {
    display: none;
}
.sidebar_toggle_button {
    display: none;
    position: relative;
    width: calc(100% + 2.5rem);
    height: 30px;
    margin-top: -1.5rem;
    margin-bottom: 1rem;
}
.mobile_overlay {
    position: absolute;
    opacity: 0;
    transition: opacity 0.8s;
    -webkit-transition: opacity 0.8s;
    background-color: #000;
}
@media screen and (max-width: 1200px) {
    .sidebar_wrapper {
        position: relative;
        right: -255px;
        transform: translate(255px, 0);
    }
    .sidebar_wrapper.sidebar_show_overlay {
        z-index: 101;
        right: 0;
        transform: none;
    }
    .content_wrapper {
        /* margin-right: calc(-255px + 2.5rem); */
        margin-right: -255px;
    }
    .sidebar_toggle_in_content {
        display: flex;
    }
    .mobile_overlay_show {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        opacity: 0.5;
        z-index: 9;
    }
    .sidebar_toggle_button {
        display: initial;
    }
}
@media screen and (max-width: 475px) {
    .content_wrapper {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}
@media screen and (max-width: 425px) {
    .content_wrapper {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}
@media screen and (max-width: 375px) {
    .content_wrapper {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
