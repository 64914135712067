.like-icon {
    width: 18px;
    height: 16px;
    display: inline-block;
    position: relative;
    margin-left: 0.25em;
    font-size: 1.5rem;
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyMSAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAuMTAxIDQuNDE3UzguODk1LjIwNyA1LjExMS4yMDdjLTQuNDY1IDAtMTAuOTY3IDYuODQ2IDUuMDgyIDE3LjU5MkMyNS4yMzcgNy4wMyAxOS42NjUuMjAyIDE1LjUwMS4yMDJjLTQuMTYyIDAtNS40IDQuMjE1LTUuNCA0LjIxNXoiIGZpbGw9IiNGRjZFNkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==")
        no-repeat center;
    background-size: 100%;
}

.liked .like-icon [class^="heart-animation-"] {
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyMSAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAuMTAxIDQuNDE3UzguODk1LjIwNyA1LjExMS4yMDdjLTQuNDY1IDAtMTAuOTY3IDYuODQ2IDUuMDgyIDE3LjU5MkMyNS4yMzcgNy4wMyAxOS42NjUuMjAyIDE1LjUwMS4yMDJjLTQuMTYyIDAtNS40IDQuMjE1LTUuNCA0LjIxNXoiIGZpbGw9IiNGRjZFNkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==")
        no-repeat center;
    background-size: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 14px;
    opacity: 0;
}

.NotebookInfoCard {
    border-bottom-width: 1.5px;
    border-color: rgb(219, 222, 254);
}
@media screen and (max-width: 960px) {
    .NotebookInfoCard {
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }
}

@media screen and (max-width: 375px) {
    .NotebookInfoCard {
        width: 100%;
    }
}
