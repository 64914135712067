.send_btn {
    place-self: flex-end;
}

@media screen and (max-width: 1300px) {
    .upload_form_wrapper {
        width: 55%;
    }
}
@media screen and (max-width: 1000px) {
    .upload_form_wrapper {
        width: 60%;
    }
}
@media screen and (max-width: 900px) {
    .upload_form_wrapper {
        width: 70%;
    }
    .chosen_commit {
        width: 80%;
    }
}
@media screen and (max-width: 800px) {
    .upload_form_wrapper {
        width: 75%;
    }
    .chosen_commit {
        width: 85%;
    }
}
@media screen and (max-width: 750px) {
    .upload_form_wrapper {
        width: 80%;
    }
    .chosen_commit {
        width: 90%;
    }
}
@media screen and (max-width: 700px) {
    .upload_form_container {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
@media screen and (max-width: 665px) {
    .menu_list {
        width: 75%;
    }
    .chosen_commit {
        width: 100%;
    }
}
@media screen and (max-width: 650px) {
    .upload_form_wrapper {
        width: 85%;
    }
}
@media screen and (max-width: 630px) {
    .upload_form_container {
        padding-left: 0;
        padding-right: 0;
    }
}
@media screen and (max-width: 600px) {
    .upload_form_wrapper {
        width: 90%;
    }
    .chosen_commit {
        flex-direction: column;
        padding: 0.75rem 1rem 0.5rem;
    }
    .chosen_commit > button {
        place-self: flex-end;
    }
}
@media screen and (max-width: 570px) {
    .upload_form_wrapper {
        width: 95%;
    }
}
@media screen and (max-width: 550px) {
    .upload_form_wrapper {
        width: 100%;
    }
}
@media screen and (max-width: 520px) {
    .divider {
        width: 85%;
    }
}
@media screen and (max-width: 500px) {
    .menu_list {
        width: 60%;
    }
    .modal_content {
        width: 80% !important;
    }
    .modal_send {
        flex-direction: column;
    }
    .new_commit_name {
        width: 100%;
        max-width: unset !important;
        margin-bottom: 0.5rem !important;
    }
}

@media screen and (max-width: 400px) {
    .divider {
        width: 100%;
    }
}
@media screen and (max-width: 340px) {
}
