.header_main {
    height: 3.5em;
    position: relative;
    z-index: 999;
}
.sub {
    font-weight: 200;
    margin: 0rem 0.5rem 0;
    padding: 0.25rem 0.4rem;
    font-size: 0.7rem;
    font-family: monospace;
    border-radius: 5px;
    vertical-align: sub;
}
.sup {
    font-weight: 500;
    margin-right: 0.25rem;
    padding: 0.1rem 0.5rem;
    font-size: 0.7rem;
    border-radius: 5px;
}
.header_links_break {
    display: none;
}
.logo_container {
    transition: all 1.5s;
}
@media screen and (max-width: 850px) {
    .header_links_break {
        display: flex;
    }
    .header_links {
        display: none;
    }
}
@media screen and (max-width: 425px) {
    .drawer_links {
        flex-direction: column;
        align-items: center;
    }
    .drawer_links > a {
        margin: 0.75rem auto;
    }
}
