.terms_container > :first-child {
    margin-top: 0.5em;
}
.terms_container ul {
    list-style: disc;
    margin-right: 2rem;
}
@media screen and (max-width: 900px) {
    .bottom_description > p {
        width: 75%;
    }
}
@media screen and (max-width: 768px) {
    .top_description {
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 560px) {
    .bottom_description {
        flex-direction: column;
    }
    .bottom_description > p {
        width: 100%;
    }
    .bottom_description > button {
        place-self: flex-end;
    }
}
