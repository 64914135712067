.path_form_helper {
    visibility: hidden;
    display: none;
}
@media screen and (max-width: 1260px) {
    .form {
        width: 85%;
    }
}
@media screen and (max-width: 1100px) {
    .form,
    .information {
        width: 100%;
    }
}
@media screen and (max-width: 900px) {
    .main_container {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .path_form_helper {
        visibility: visible;
        display: flex;
    }
}
@media screen and (max-width: 600px) {
    .main_container {
        flex-direction: column;
    }
}
