.profilePictureDarkHover {
    height: 7rem;
    width: 7rem;
    background: rgba(0, 0, 0, 0);
    opacity: 0;
    border-radius: 999px;
    grid-column: 1;
    grid-row: 1;
    margin: 0 1.25rem;
    z-index: 999;
    position: relative;
    transition: 0.1s all;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.profilePictureDarkHover:hover {
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
}
.container {
    margin-right: 0.5rem;
}
@media screen and (max-width: 900px) {
    .container {
        margin-right: 0;
    }
}
@media screen and (max-width: 768px) {
    .infos_container {
        flex-direction: column;
    }
}
